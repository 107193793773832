import getTarget from 'javascripts/utils/get-target';
import { MEDIA_QUERIES } from 'javascripts/constants';

// Tab bar
export default class TabBar {
  constructor($tabs, resizeObserver) {
    // Get references
    this.$tabs = $tabs;
    this.$items = this.$tabs.querySelector('.tabs__list');
    this.items = this.$items.querySelectorAll('.tabs__list-link');
    this.$select = this.$tabs.querySelector('.tabs__select-button');
    this.$content = this.$tabs.querySelector('.tabs__content');

    // Is section list
    this.isSectionList = this.$tabs.classList.contains('tabs--section');

    // Register with observer
    resizeObserver.observe(this.$tabs);

    // Outside click
    this.onOutsideClickBinded = this.onOutsideClick.bind(this);

    // Outside focus
    this.onOutsideFocusBinded = this.onOutsideFocus.bind(this);

    // Register events
    this.$select.addEventListener('click', this.onSelect.bind(this));
    this.$select.addEventListener('keydown', this.onSelectKeydown.bind(this));
    this.$items.addEventListener('click', this.onItem.bind(this));
    window.addEventListener('hashchange', this.onHashchange.bind(this));
    this.$tabs.addEventListener('resendEvents', this.onResendEvents.bind(this));

    // Select tab per URL parameter
    // this.onHashchange();

    // Check if Overlay was opened via URL
    const hash = window.location.hash.split('#')[1]; // eslint-disable-line
    if (hash) {
      this.openByHash();
      // const isDialogContent = document.querySelector(`#${hash}`)
      //  .classList.contains('tabs__headline');
      // if (isDialogContent) {
      //   const foundDialogContent = document.querySelector(`#${hash}`);

      //   if (foundDialogContent.closest('.tabs__panel')) {
      //     const closestSectionId = foundDialogContent.closest('.tabs__panel').id;

      //     this.items.forEach(($item) => {
      //       if ($item.hash.includes(closestSectionId)) {
      //         console.log('here on pageload');
      //         $item.click();
      //         $item.scrollIntoView();
      //       }
      //     });
      //   }
      // }
    }
  }

  static onResize(tabBars) {
    tabBars.forEach((tabBar) => {
      const $tabs = getTarget(tabBar.target, '.tabs');
      const $items = $tabs.querySelector('.tabs__list');

      // Reset back
      $tabs.classList.remove('tabs--menu');
      $tabs.classList.add('tabs--list', 'tabs--uninitalized');

      // Get widths and collapse status
      const tabBarWidth = $tabs.getBoundingClientRect().width;
      let itemsWidth = 1;
      $items.querySelectorAll('.tabs__list-item').forEach(($item) => {
        itemsWidth += $item.getBoundingClientRect().width - 1;
      });
      const isCollapsed = itemsWidth > tabBarWidth;

      // Switch between list and menu
      TabBar.switchBetweenListAndMenu($tabs, isCollapsed);
    });
  }

  static switchBetweenListAndMenu($tabs, isCollapsed) {
    const $items = $tabs.querySelector('.tabs__list');

    // Switch class
    $tabs.classList[isCollapsed ? 'add' : 'remove']('tabs--menu');
    $tabs.classList[isCollapsed ? 'remove' : 'add']('tabs--list');

    // Remove uninitalized class
    $tabs.classList.remove('tabs--uninitalized');

    // Switch role of list container
    $items.setAttribute('role', 'tablist');
    $items.hidden = isCollapsed;

    // Update list items
    $items.querySelectorAll('.tabs__list-link').forEach(($item) => {
      // Switch role
      $item.setAttribute('role', 'tab');

      // Update aria props for current active item
      if (isCollapsed && $item.getAttribute('aria-selected') === 'true') {
        $item.removeAttribute('aria-selected');
        $item.setAttribute('aria-checked', 'true');
      } else if (!isCollapsed && $item.getAttribute('aria-checked') === 'true') {
        $item.removeAttribute('aria-checked');
        $item.setAttribute('aria-selected', 'true');
      }
    });
  }

  initSectionList() {
    // Remove uninitalized class
    this.$tabs.classList.remove('tabs--uninitalized');

    // Switch between list and menu
    const switchOnMediaQueryChange = (mq) => {
      TabBar.switchBetweenListAndMenu(this.$tabs, !mq.matches);
    };

    // Init media query
    const mql = window.matchMedia(MEDIA_QUERIES.m);
    mql.addListener(switchOnMediaQueryChange);
    switchOnMediaQueryChange(mql);
  }

  openMenu() {
    // Set state
    this.$select.setAttribute('aria-expanded', 'true');
    this.$items.hidden = false;

    // Get current active item
    const $checked = this.$items.querySelector('[aria-checked="true"]');
    $checked.focus();

    // Catch outside click/focus
    document.addEventListener('click', this.onOutsideClickBinded);
    document.addEventListener('focusin', this.onOutsideFocusBinded);
  }

  closeMenu() {
    // Set state
    this.$select.setAttribute('aria-expanded', 'false');
    this.$items.hidden = true;
  }

  toggleMenu() {
    const expanded = this.$select.getAttribute('aria-expanded') === 'true';
    return expanded ? this.closeMenu() : this.openMenu();
  }

  focusNext($currentItem, $startItem) {
    // Determine which item is the startItem (first or last)
    const down = $startItem === this.items[0];

    // Helper function for getting next legitimate element
    const move = ($el) => {
      const $nextThing = (down
        ? $el.parentNode.nextElementSibling : $el.parentNode.previousElementSibling);

      return ($nextThing && $nextThing.firstElementChild) || $startItem;
    };

    // Move and focus
    const $nextItem = move($currentItem);
    $nextItem.focus();
  }

  openByHash() {
    if (window.location.hash) {
      // console.log('openByHash');
      const hash = window.location.hash.substring(1);
      const $target = document.getElementById(hash);

      if ($target && $target.matches('.tabs__list-link')) {
        $target.click();
        $target.scrollIntoView();
      } else if ($target && $target.matches('.tabs__headline')) {
        const foundDialogContent = document.querySelector(`#${hash}`);

        if (foundDialogContent.closest('.tabs__panel')) {
          const closestSectionId = foundDialogContent.closest('.tabs__panel').id;

          this.items.forEach(($item) => {
            if ($item.hash.includes(closestSectionId)) {
              $item.click();
              $item.scrollIntoView();
            }
          });
        }
      }
    }

    return false;
  }

  onOutsideClick(event) {
    if (!this.$items.contains(event.target) && !this.$select.contains(event.target)) {
      document.removeEventListener('click', this.onOutsideClickBinded);
      this.closeMenu();
    }
  }

  onOutsideFocus(event) {
    if (!event.target.closest('.tabs__list') && !this.$select.contains(event.target)) {
      this.closeMenu();
    }
  }

  onHashchange() {
    // console.log('onHashchange');
    this.openByHash();
  }

  onResendEvents() {
    if (!this.openByHash()) {
      // console.log('onResendevents');
      const $item = this.$items.querySelector('.tabs__list-link[aria-selected="true"]');
      if ($item) {
        this.onItem({
          target: $item,
          preventDefault: () => { },
        }, true);
      }
    }
  }

  onSelect() {
    this.toggleMenu();
  }

  onSelectKeydown(event) {
    // Also toggle on down arrow
    if (event.keyCode === 40) {
      if (this.$items.hidden) {
        this.openMenu();
      } else {
        this.items[0].focus();
      }
    }

    // close menu on up arrow
    if (event.keyCode === 38) {
      this.closeMenu();
    }
  }

  onItem(event) {
    const $target = getTarget(event.target, '.tabs__list-link');
    const isMenuCurrently = this.$tabs.classList.contains('tabs--menu');

    if ($target) {
      // If target is on the same page, prevent navigation
      if (this.isSamePage($target)) {
        event.preventDefault();
      }

      // Remove old active item
      this.items.forEach(($item) => {
        $item.classList.remove('tabs__list-link--active');
        $item.removeAttribute('aria-selected');
        $item.removeAttribute('aria-checked');
      });

      // Set new active
      $target.setAttribute(isMenuCurrently ? 'aria-checked' : 'aria-selected', 'true');
      $target.classList.add('tabs__list-link--active');

      // Update select text
      const $currentSelect = this.$select.querySelector('.tabs__select-title');
      $currentSelect.innerHTML = $target.innerHTML;

      // Close menu
      if (isMenuCurrently) {
        this.closeMenu();
      }

      this.togglePanel($target);
    }
  }

  togglePanel($item) {
    // Get current panel
    const $currentPanel = this.$content.querySelector('.tabs__panel:not([hidden])');

    // Hide current panel
    $currentPanel.removeAttribute('tabindex');
    $currentPanel.setAttribute('hidden', '');

    // Find new panel
    const $headline = this.$content.querySelector($item.getAttribute('aria-controls'));
    const $panel = $headline.closest('.tabs__panel');

    // Show new panel
    $panel.removeAttribute('hidden');
    // Focus on headline interferes with correct scroll-top-margin setting
    // $headline.focus();
  }

  isSamePage($link) {
    return (
      $link.protocol !== window.location.protocol
      || $link.host !== window.location.host
      || $link.pathname !== window.location.pathname
      || $link.search !== window.location.search
    ) === false;
  }
}

// Init
const observer = new ResizeObserver(TabBar.onResize);
document.querySelectorAll('.js-tabs').forEach($tabs => new TabBar($tabs, observer));
